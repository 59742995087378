<template>
    <CCard class="card-secondary card-outline" v-bind:class="{ 'collapsed-card': collapse }">
        <CCardHeader @click="toggle">
            <div class="clearfix">
                <div class="float-left">
                    <slot name="headerLeft">
                        <h5 class="mb-0">
                            <a href="javascript:void(0)">{{this.headerTitle}} </a>
                        </h5>
                    </slot>
                </div>
                <div class="float-right">
                    <slot name="headerLeft">
                        <CIcon name="cil-plus" v-if="collapse"></CIcon>
                        <CIcon name="cil-minus" v-else></CIcon>
                    </slot>
                </div>
            </div>
        </CCardHeader>
        <CCollapse :show="!collapse" @finish="finishAnimation">
            <CCardBody>
                <slot name="body">
                    Empty body
                </slot>
            </CCardBody>
            <CCardFooter v-if="showFooter">
                <slot name="footer"></slot>
            </CCardFooter>
        </CCollapse>
    </CCard>
</template>

<script>
    export default {
        name: 'Accordion',
        props: {
            headerTitle: String,
            expand: {
                default: true,
                type: Boolean
            },
            showFooter: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                collapse: !this.expand
            };
        },
        methods: {
            show() {
                this.collapse = false;
            },
            hide() {
                this.collapse = true;
            },
            toggle() {
                this.collapse = !this.collapse;
            },
            finishAnimation() {
                console.log('Finish Animation');
            },
            onHeaderClick() {
                console.log('click on header');
            }
        }
    }
</script>

<style scoped>
    header {
        /*background: #3c4b64*/
    }
    h5 {
        font-size: 1.09375rem;
    }
        h5 a {
            /* color: #fff;*/
            color: #444;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }
</style>
