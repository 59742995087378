<template>
    <CModal title="Tập đoàn Hoàng Minh Trung"
            color="warning"
            :show.sync="warningModal">
        {{warningModalMessage}}

        <template v-slot:footer>
            <CButton type="button" color="primary" @click="handleOk">{{ $t('common.ok') }}</CButton>
            <CButton type="button" color="secondary" @click="handleCancel">{{ $t('common.cancel') }}</CButton>
        </template>
    </CModal>
</template>

<script>   
    export default {
        name: 'Confirmation',
        props: {
            items: Array,
            //onOk: { type: Function },
            //onCancel: { type: Function}
        },
        data() {
            return {
                warningModal: false,
                warningModalMessage: "Default Message",
            };
        },
        methods: {
            show(msg, onOkCallback, onCancelCallback) {
                if (msg)
                    this.warningModalMessage = msg;
                if (onOkCallback instanceof Function)
                    this.onOk = onOkCallback;
                if (onCancelCallback instanceof Function)
                    this.onCancel = onCancelCallback;
                this.warningModal = true;
            },
            hide() {
                this.warningModal = false;
            },
            handleOk() {
                this.hide();
                if (this.onOk instanceof Function)
                    this.onOk();
            },
            handleCancel() {
                this.hide();
                if (this.onCancel instanceof Function)
                    this.onCancel();
            }
        }
    }
</script>
