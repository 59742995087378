<template>
    <CRow>
        <CCol sm="12">
            <div class="content-header clearfix">
                <h1 class="float-left">
                    {{this.headerTitle}}
                        <slot name="back">
                            <small v-if="showGoBack==true">
                                <CIcon name="cil-arrow-circle-left"></CIcon>
                                <a href="javascript:void(0)" @click="handleGoBack">{{this.backTitle}}</a>
                            </small>
                        </slot>
                </h1>
                <div class="float-right">
                    <slot name="headerRight">

                    </slot>
                </div>
            </div>
        </CCol>
    </CRow>
</template>

<script>
    export default {
        name: 'TopHeadline',
        props: {
            headerTitle: String,
            expand: {
                default: true,
                type: Boolean
            },
            showGoBack: {
                default: true,
                type: Boolean
            },
            backTitle: {
                default: "Back",
                type: String
            },
            onGoBack: { type: Function },
        },
        data() {
            return {
               
            };
        },
        methods: {
            handleGoBack() {
                if (this.onGoBack instanceof Function)
                    this.onGoBack();
            },
        }
    }
</script>

<style scoped>
    .btn-primary {
        background-color: #3c8dbc;
        border-color: #367fa9;
    }
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #0062cc;
        border-color: #005cbf;
    }

    .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }
    a:hover {
        color: #0056b3;
        text-decoration: none;
    }
    a {
        color: #0056b3;
    }
    .content-header h1 {
        font-size: 1.8rem;
        margin: 0;
    }
    .content-header > h1 {
        margin-bottom: 10px;
    }
    .float-left {
        float: left !important;
    }
    .float-right {
        float: right !important;
    }
    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .content-header > h1 > small {
        color: #0076bb;
        font-weight: 400;
        margin-left: 6px;
        font-size: 65%;
    }

    .content-header > h1 > small i {
        font-size: .8em;
        padding-right: 2px;
    }

    header {
       background: #3c4b64
    }
    h5 {
        font-size: 1.09375rem;
    }
        h5 a {
            color: #fff;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }
    .float-right button{
        margin-left: 0.2rem;
    }
</style>
