<template>
	<CRow>
		<CCol sm="12">
			<TopHeadline :headerTitle="$t('pages.account.editProfile')" :backTitle="$t('common.back')" :onGoBack="onBackPage">
				<template v-slot:headerRight v-if="editingProfile != null">
					<CButton type="button" color="primary" class="btn-primary" @click="onUpdateProfile">{{ $t('common.save') }}</CButton>

					<button v-if="editingProfile.profileStatus != 2" type="button" name="accept-Verify" class="btn btn-success" @click="onRejectVerify">
						{{ $t('pages.account.verifying') }}
					</button>
				</template>
			</TopHeadline>

			<Accordion :headerTitle="$t('pages.account.accountInfomation')" v-if="editingProfile != null">
				<template v-slot:body>

					<CRow>
						<CCol sm="12">
							<CForm>
								<CInput :label="$t('common.userName')" :value="editingProfile.userName"
										readonly horizontal>
								</CInput>
								<CInput :label="$t('common.email')" :value="editingProfile.email"
										readonly horizontal>
								</CInput>

								<CInput :label="$t('pages.account.fullName')" :placeholder="$t('pages.account.fullName')" v-model="editingProfile.fullName"
										horizontal>
								</CInput>
								<CInput :label="$t('common.phone')" :placeholder="$t('common.enterPhoneNumber')" v-model="editingProfile.phoneNumber"
										@keypress="onlyNumbers" horizontal>
								</CInput>

								<CInput :label="$t('common.address')" :placeholder="$t('pages.account.enterAddress')" v-model="editingProfile.address"
										horizontal>
								</CInput>

								<div role="group" class="form-group form-row">
									<label class="col-sm-3 col-form-label">{{ $t('pages.account.gender') }}</label>
									<div class="col-sm-9 input-group">
										<select name="ddlProducts" class="form-control" v-model="editingProfile.gender">
											<option value="0">{{ $t('pages.account.selectGender') }}</option>
											<option value="1">{{ $t('pages.account.male') }}</option>
											<option value="2">{{ $t('pages.account.female') }}</option>
										</select>
									</div>
								</div>

								<CInput :label="$t('pages.account.cmnd')" :placeholder="$t('pages.account.entercmnd')" v-model="editingProfile.cmnd"
										horizontal>
								</CInput>

                                <CInput :label="$t('pages.account.dayOfBirth')" type="date" :formatter="formatDate" v-model="editingProfile.dayOfBirth"
                                        horizontal>
                                </CInput>								
							</CForm>
						</CCol>
					</CRow>

					<div class="form-actions clearfix">
						<span class="requiredInput">{{ $t('pages.account.verifyNotice')}}</span>
					</div>
				</template>
			</Accordion>

			<!-- Show CMND images -->
			<Accordion :headerTitle="$t('pages.account.photoOfIdentityCard')" v-if="editingProfile != null">
				<template v-slot:body>
					<CRow v-if="pictureModels != null && pictureModels.length > 0">
						<CCol sm="12">
							<CCard>
								<CCardHeader>
									<strong>{{ $t('pages.account.photoOfIdentityCard') }} </strong>
								</CCardHeader>
								<CCardBody>
									<CDataTable :items="pictureModels"
												:fields="fields">
										<template #cmnd_image="{item}">
											<td>
												<img :src="item.thumbImageUrl" />
											</td>
										</template>
										<template #picture_actions="{item}">
											<td class="py-2">
												<CButton color="primary"
														 variant="outline"
														 square
														 size="sm"
														 @click="onDeleteImage(item)">
													{{ $t('common.remove') }}
												</CButton>
											</td>
										</template>
										
									</CDataTable>
								</CCardBody>
							</CCard>
						</CCol>
					</CRow>

					<!-- Upload image -->
					<CRow>
						<CCol sm="12">
							<CCard>
								<CCardHeader>
									<strong>{{ $t('pages.account.uploadPhotoOfIdentityCard') }}</strong>
								</CCardHeader>
								<CCardBody>
									<div>
										<UploadImages ref="uploadimages" :max="2" @change="handleIdentityIdCardImages" :uploadMsg="$t('common.uploadImageMessage')" />
									</div>
									<div class="form-actions">
										<CButton v-if="files != null && files.length > 1" type="button" color="primary" @click="onUploadIdentityIdCardImages">{{ $t('pages.account.uploadIdentityCardImages') }}</CButton>										
									</div>
								</CCardBody>
							</CCard>
						</CCol>
					</CRow>
				</template>
			</Accordion>
		</CCol>

		<Confirmation ref="confirmation"></Confirmation>
	</CRow>
</template>

<script>
	import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'
	import UploadImages from "vue-upload-drop-images"
    import Accordion from '@/components/Accordion.vue'
	import TopHeadline from '@/components/TopHeadline.vue'
	import Confirmation from '@/components/Confirmation.vue'

	export default {
		name: 'profileEdit',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				invalidInput: false,
				errorInput: '',
				files: null,
                fields: [                      // Array of column object data                  
                    { key: "cmnd_image", label: i18n.t('pages.account.frontAndBackImage') },
                    {
                        key: 'picture_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
			}
		},
        components: {
			UploadImages,
            Accordion,
			TopHeadline,
			Confirmation,       
		},
		computed: {
           // ...mapGetters('accountProfile'),
			...mapGetters('profile', ['editingProfile', 'pictureModels']),			
        },
        methods: {
            async onUpdateProfile() {
				//console.log("Update Progile.......");

                var retVal = await this.$store.dispatch("profile/saveUserProfile");
                if (retVal == true) {
                    this.$router.go(-1);
                }
            },
            onBackPage() {              
                this.$router.go(-1);
            },
            validator(val) {
                return val ? val.length > 0 : false
            },           
            onlyNumbers(event) {
                let keyCode = event.keyCode ? event.keyCode : event.which;
                if (keyCode < 48 || keyCode > 57) {
                    // 46 is dot
                    event.preventDefault();
                }
			},
            formatDate(value) {
                return this.$moment(value).format('DD-MM-YYYY');
			},
            async handleIdentityIdCardImages(files) {
				this.files = files;				
			},
            async onUploadIdentityIdCardImages() {
                if (this.files == null || this.files.length < 2) {
                    //this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.confirmUploadimage') }, { root: true });
                    this.$store.dispatch('alert/error', this.$t('pages.account.confirmUploadimage'), { root: true });
                    return;
				}
				

				var retVal = await this.$store.dispatch("profile/uploadIdentityIdCardImages", { userId: this.editingProfile.id, files: this.files });
               // console.log("uploadIdentityIdCardImage result ==: ", retVal);

                if (retVal === true || retVal.success === true) {
                    this.$store.dispatch("profile/getAllIdentityIdCardImages", this.editingProfile.id);
					this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticesUploadimage') }, { root: true });

					this.$refs.uploadimages.reset();
					//this.$refs.uploadimages.value=null;
				}
				else {
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticesUploadimageError') }, { root: true });
                }
			},
			onRejectVerify() {
                let _profileId = this.editingProfile.id;
                this.$refs.confirmation.show(i18n.t('common.confirmMakeChange'), async () => {

                    var retVal = await this.$store.dispatch("accountProfile/changeAccountProfileStatus", { profileId: _profileId, newStatus: 1 });
                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                    }
                });
			},
            onDeleteImage(item) {
                this.$refs.confirmation.show(i18n.t('common.confirmDelete'), async () => {                    
					var retVal = await this.$store.dispatch("accountProfile/deteIdentityIdCardImages", item.id);                   
                    if (retVal == true) {
                        this.$store.dispatch("profile/getAllIdentityIdCardImages", this.$route.params.id);
                    }
                });
            },
        },
        mounted() {
			this.$store.dispatch("profile/getProfileInfo", this.$route.params.id);
            this.$store.dispatch("profile/getAllIdentityIdCardImages", this.$route.params.id);
        }
    }
</script>